@import '@styles/scss';

.desktop {
  @include media(tablet, mobile) {
    display: none;
  }
}

.mobile {
  display: none;

  @include media(tablet, mobile) {
    display: block;
  }
}

.background {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 775px;

  background-color: #1a1623;

  @include media(tablet, mobile) {
    height: auto;
  }
  @include media(tablet) {
    padding: 100px 0px;
  }
  @include media(mobile) {
    padding: 50px 0px;
  }

  .mask {
    position: absolute;
    pointer-events: none;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.13);
  }

  .desktop.image {
    width: 454px;
    height: 548.47px;

    filter: drop-shadow(0px 0px 100px rgba(149, 143, 160, 0.35));

    @include media(tablet, mobile) {
      display: none;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 1200px;
    margin: 0 auto;

    background-color: transparent;

    @include media(tablet, mobile) {
      display: block;
    }

    .content {
      display: flex;
      flex-direction: column;
      text-align: right;

      row-gap: 40px;

      @include media(tablet, mobile) {
        align-items: center;
        text-align: center;
      }
      @include media(tablet) {
        row-gap: 50px;
      }
      @include media(mobile) {
        row-gap: 20px;
      }

      .image_container {
        display: none;

        @include media(tablet, mobile) {
          display: block;

          width: 100%;
          height: 100%;
        }
        @include media(tablet) {
          padding: 0px 100px;
        }
        @include media(mobile) {
          padding: 0px 60px;
        }

        .mobile.image {
          width: 454px;
          height: 548.47px;

          filter: drop-shadow(0px 0px 100px rgba(149, 143, 160, 0.35));

          @include media(tablet, mobile) {
            width: 100%;
            height: 100%;
          }
          @include media(tablet) {
            margin-top: 20px;
          }
          @include media(mobile) {
            margin-top: 15px;

            filter: unset;
          }
        }
      }

      .interactions {
        display: flex;
        flex-direction: row;
        align-self: flex-end;

        column-gap: 19.6px;
        margin-top: -20px;

        @include media(tablet, mobile) {
          align-self: center;
          flex-direction: column;
        }
        @include media(tablet) {
          row-gap: 15px;
          margin-top: 30px;
        }
        @include media(mobile) {
          row-gap: 7.48px;
          column-gap: 17.46px;
          margin-top: 10px;
        }

        .interactions_mobile {
          display: flex;
          flex-direction: row;
          align-items: center;

          column-gap: 19.6px;

          @include media(tablet) {
            column-gap: 35px;
          }

          .divider {
            width: 1px;
            height: 24px;

            background-color: $white;
            opacity: 0.3;
          }
          .divider.dividerMobile {
            @include media(tablet, mobile) {
              display: none;
            }
          }

          .interaction {
            display: flex;
            flex-direction: column;
            align-items: center;

            row-gap: 5px;

            .icon {
              width: 33px;
              height: 33px;

              @include media(tablet) {
                width: 52px;
                height: 52px;
              }
              @include media(mobile) {
                width: 25.93px;
                height: 25.93px;
              }
            }
            .text {
              font-family: NotoSansKR-Regular;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: -0.015em;

              color: $gray500;

              @include media(tablet) {
                font-size: 22.12px;
                line-height: 33.17px;
              }
              @include media(mobile) {
                font-size: 11.03px;
                line-height: 16.54px;
              }
            }
          }
        }
      }
    }
  }
}
