@import '@styles/scss';

.no_deco_anchor {
  text-decoration: none;

  @include media(tablet) {
    max-width: 288px;
  }
  @include media(mobile) {
    max-width: 143.6px;
  }

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 5px;
    border: 1px solid $white;
    border-radius: 24px;
    padding: 7px 28px;

    font-family: NotoSansKR-Regular;
    font-size: 14px;
    line-height: 34px;
    letter-spacing: -0.04em;

    color: $white;
    background-color: transparent;

    cursor: pointer;

    @include media(tablet, mobile) {
      display: none;
    }
  }

  .button_image {
    display: none;

    @include media(tablet, mobile) {
      display: block;

      width: 100%;
    }
  }
}
