@import '@styles/scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 10px;
  column-gap: 20px;

  .anchor {
    font-family: Pretendard-Regular;
    font-size: 14px;
    line-height: 22px;

    color: $white;

    @include media(tablet) {
      font-size: 22px;
      line-height: 30px;
    }
    @include media(mobile) {
      font-size: 10.97px;
      line-height: 14.96px;
    }
  }
  .bold {
    font-weight: 700;
  }

  .divider {
    width: 1px;
    height: 12px;

    background-color: $white;

    @include media(mobile) {
      height: 5.99px;
    }
  }
}
