@import '@styles/scss';

.background {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 667px;

  background-color: #1a1623;

  @include media(tablet, mobile) {
    height: auto;
  }
  @include media(tablet) {
    padding-top: 100px;
  }
  @include media(mobile) {
    padding-top: 50px;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 1200px;
    height: 100%;
    margin: 0 auto;

    background-color: transparent;

    @include media(tablet, mobile) {
      display: block;
      text-align: center;

      width: 100%;
      height: auto;
    }

    .content {
      display: flex;
      flex-direction: column;

      row-gap: 40px;

      @include media(tablet) {
        row-gap: 50px;
        margin-bottom: 70px;
      }
      @include media(mobile) {
        row-gap: 20px;
        margin-bottom: 35px;
      }
    }

    .imageContainer {
      position: relative;
      align-self: flex-end;

      margin-bottom: -2px;

      @include media(tablet) {
        padding: 0px 100px;
      }
      @include media(mobile) {
        padding: 0px 48.12px;
      }

      .mask {
        position: absolute;
        left: 0;

        width: 100%;
        height: 100%;

        background: linear-gradient(180.04deg, rgba(0, 0, 0, 0) 20%, rgba(26, 22, 35, 0.95) 100%);
      }
      .image {
        width: 529.46px;
        height: 610px;

        @include media(tablet, mobile) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
