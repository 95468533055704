// ! Must Sync with "@utils/common/contants.ts"
$mobile: 768px;
$tablet: 1024px;

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $mobile) and (max-width: $tablet) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == mobile) {
      @include mobile {
        @content;
      }
    } @else if ($key == tablet) {
      @include tablet {
        @content;
      }
    }
  }
}
