@import '@styles/scss';

.body {
  font-family: NotoSansKR-Regular;

  font-weight: 400;
  font-size: 24px;
  line-height: 34.75px;
  letter-spacing: -0.04em;

  white-space: pre-line;

  color: $black;

  @include media(mobile) {
    font-size: 12px;
    line-height: 17.38px;
  }
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}
