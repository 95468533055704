@import '@styles/scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 85.6px;
  border-bottom: 1px solid $gray300_opacity05;

  background-color: $gray800;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 1200px;
    padding: 0px 20px;
    column-gap: 15px;
    margin: 0 auto;

    @include media(tablet, mobile) {
      justify-content: center;

      width: 100%;
      margin: initial;
    }

    .zuicy_logo_symbol {
      width: 37.6px;
      height: 37.6px;

      @include media(mobile) {
        width: 18.75px;
        height: 18.75px;
      }
    }

    .zuicy_logo_text {
      width: 150px;
      height: 37.6px;

      @include media(mobile) {
        width: 74.79px;
        height: 18.76px;
      }
    }
  }
}
