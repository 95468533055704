@import '@styles/scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 5px;
  margin: 10px;

  @include media(mobile) {
    row-gap: 2.49px;
  }

  .address {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 10px;

    @include media(tablet, mobile) {
      flex-direction: column;
    }
    @include media(tablet) {
      row-gap: 5px;
    }
    @include media(mobile) {
      row-gap: 2.49px;
    }

    .divider {
      width: 1px;
      height: 12px;

      background-color: $white;

      &.mobile {
        @include media(tablet, mobile) {
          display: none;
        }
      }
    }
  }

  .text {
    font-family: Pretendard-Regular;
    font-size: 14px;
    line-height: 22px;

    color: $white;

    @include media(tablet) {
      font-size: 22px;
      line-height: 30px;
    }
    @include media(mobile) {
      font-size: 10.97px;
      line-height: 14.96px;
    }
  }
}
