@import '@styles/scss';

.head {
  font-family: Pretendard-Regular;

  font-weight: 400;
  font-size: 50px;
  line-height: 70px;
  letter-spacing: -0.04em;

  white-space: pre-line;

  color: $black;

  @include media(tablet) {
    font-size: 55px;
    line-height: 71.5px;
  }
  @include media(mobile) {
    font-size: 28px;
    line-height: 36.4px;
  }
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}
