@import '@styles/scss';

.desktop {
  @include media(tablet, mobile) {
    display: none;
  }
}

.mobile {
  display: none;

  @include media(tablet, mobile) {
    display: block;
  }
}

.background {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 814px;

  background: linear-gradient(180deg, #3a3644 0%, #1a1623 100%);

  @include media(tablet, mobile) {
    height: auto;
  }
  @include media(tablet) {
    padding: 100px 0px;
  }
  @include media(mobile) {
    padding: 50px 0px;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 1200px;
    margin: 0 auto;

    @include media(tablet, mobile) {
      display: block;

      width: 100%;
      height: auto;
    }

    .content {
      display: flex;
      flex-direction: column;

      row-gap: 40px;

      @include media(tablet, mobile) {
        text-align: center;
        align-items: center;
      }
      @include media(tablet) {
        row-gap: 30px;
      }
      @include media(mobile) {
        row-gap: 15px;
      }

      .head_container {
        display: flex;
        flex-direction: row;

        &.desktop {
          @include media(tablet, mobile) {
            display: none;
          }
        }
        &.mobile {
          display: none;
          @include media(tablet, mobile) {
            display: block;
          }
        }

        @include media(tablet, mobile) {
          flex-direction: column;
        }
      }

      .logo {
        &.mobile {
          display: none;

          @include media(tablet, mobile) {
            display: block;

            margin-bottom: 20px;
          }
          @include media(tablet) {
            width: 198.96px;
            height: 50px;
          }
          @include media(mobile) {
            width: 99.23px;
            height: 24.94px;
            margin-bottom: 5px;
          }
        }
      }

      .buttons.desktop {
        display: flex;
        flex-direction: row;

        column-gap: 10px;

        @include media(tablet, mobile) {
          display: none;
        }
      }
    }

    .image {
      width: 618px;
      height: 652px;

      @include media(tablet, mobile) {
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
      @include media(tablet) {
        margin-top: 70px;

        transform: translate(-34px, 0px);
      }
      @include media(mobile) {
        margin-top: 35px;

        transform: translate(-20px, 0px);
      }
    }

    .buttons.tablet {
      display: none;

      @include media(tablet, mobile) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;
      }
      @include media(tablet) {
        column-gap: 30px;
        padding: 0px 30px;
        margin-top: 75px;
      }
      @include media(mobile) {
        column-gap: 15px;
        padding: 0px 20px;
        margin-top: 35px;
      }
    }
  }
}
