@import '@styles/scss';

.background {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 70px 0px;

  background-color: #1a1623;

  @include media(mobile) {
    padding: 50px 0px;
  }

  .mask {
    position: absolute;
    pointer-events: none;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.15);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    row-gap: 20px;

    @include media(tablet) {
      row-gap: 30px;
    }
    @include media(mobile) {
      row-gap: 15px;
    }

    .zuicy_logo_text {
      width: 150px;
      height: 38px;

      @include media(mobile) {
        width: 74.81px;
        height: 18.95px;
      }
    }
  }
}
